<mat-card class="row-margin-tb card-dark" style="background: #ECF2F2 !important;">
  <mat-card>
    <app-app-headers *ngIf="attendanceType == 'child'" [headerProps]="buttonHeaderProps"></app-app-headers>
  </mat-card>

  <mat-card style="background-color: #ffffff26 !important; border: 0px !important;">
    <p class="text-normal"
       style="color: #4A4A4A; line-height: 8px; margin-bottom: -5px;">
      {{headerProps.searchConfig.label}}
    </p>
    <filter-control [hasButton]="true"
                    [hasClearButton]="true"
                    [layoutAlign]="layoutAlign"
                    [hasSearch]="true"
                    [hasOnlyDate]="false"
                    [hasCustomOnlyDate]="true"
                    [hasTwoDateRanges]="true"
                    [maxDate]="rangeToMaxDate"
                    [screen]="'staff-attendance'"
                    [isTwoRows]="attendanceType == 'child'"
                    [hasToggle]="hasToggle"
                    [searchConfig]="headerProps?.searchConfig"
                    [builtInFilters]="headerProps?.builtInFilters"
                    [filterArray]="headerProps?.filterArray"
                    (filnalFilters)="filnalFilters($event)"
                    [whiteBackgroundInputs]="true"
                    (buttonClickEvent)="openAddAttendanceDialog()"
                    (clearAllFilters)="clearAllFilters($event)"></filter-control>
  </mat-card>

    <app-child-attendance-table (onPagination)="onPagination($event)"
                         (selectedItem)="actionButtonOutput($event)"

                         [isExpandable]="true"
                         [nestedDataKey]="'secondaryData'"
                         [nestedColumns]="attendanceHeaderChild"
                         [isCellWidthFixed]="true"
                         [cellWidth]="'200px'"
                         height="50vh"
                         [props]="tableConfigAndProps"></app-child-attendance-table>

</mat-card>
