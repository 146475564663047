import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-initiate-split-rollback-dialog',
  templateUrl: './initiate-split-rollback-dialog.component.html',
  styleUrls: ['./initiate-split-rollback-dialog.component.scss']
})
export class InitiateSplitRollbackDialogComponent implements OnInit {

  isSplitInvoice: boolean = true;
  splitInvoice: any;
  invoice: any;
  @Inject(MAT_DIALOG_DATA) public data: any

  constructor(
    protected dialog: MatDialog,
		protected dialogRef: MatDialogRef<InitiateSplitRollbackDialogComponent>,	
  ) { }

  ngOnInit(): void {
    console.log('SPLIT ROLLBACK POPUP============>', this.data)
    if(this.data.splitInvoice){
      this.isSplitInvoice = true;
      this.splitInvoice = this.data.splitInvoice;
      this.invoice = this.data.payload
    }else{
      this.invoice = this.data
    }    
  }


  onConfirm(){
    this.dialogRef.close('confirmed')
  }

  onCancel(){
    this.dialogRef.close('cancelled');
  }



}
