import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { XeroContact } from '../../../../models/models';

@Component({
  selector: 'app-create-new-xero-contact',
  templateUrl: './create-new-xero-contact.component.html',
  styleUrls: ['./create-new-xero-contact.component.scss']
})
export class CreateNewXeroContactComponent implements OnInit {

  contact: XeroContact;
  hasXeroContact: boolean = false;
  @Inject(MAT_DIALOG_DATA) public data: any

  constructor(
    protected dialog: MatDialog, 
    protected dialogRef: MatDialogRef<CreateNewXeroContactComponent>
  ) {}


  ngOnInit(): void {
    this.contact = {
      name: this.data?.guardian?.name,
      email: this.data?.guardian?.email,
      address: this.data?.guardian?.address,
      contactNo: this.data?.guardian?.mobileNumber
    };
    if(this.data?.selectedXeroContact){
      this.hasXeroContact = true
    }
  }

  onConfirm(){
    console.log('IN XERO NEW CONTACT POPUP')    
    this.dialogRef.close('confirmed')
  }

  onCancel(){
    console.log('IN XERO NEW CONTACT POPUP')
    this.dialogRef.close('cancelled');
  }


}
