<div class="dialog-container">
    <p class="dialog-heading">Link To an Xero Contact</p>
    <svg (click)="onSkip()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M6 17.9999L17.9999 6" stroke="#192435" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.9999 17.9999L6 6" stroke="#192435" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</div>
<div>
    <p class="dialog-content">Link to existing or add new account of parent in xero</p>
</div>
<div class="dialog-footer">
    <button
       (click)="onSkip()"
       class="secondary-btn mr">
            Skip
      </button>
      <button
        (click)="onLinkWithExistingContact()"  
        class="primary-btn mr">
            Link with existing xero contact
      </button>
      <button  
        (click)="onCreateNewContact()"
        class="primary-btn">
            Create new contact in xero
      </button>
      
</div>
