<div class="dialog-container">
    <p class="dialog-heading">Choose a xero contact</p>
    <svg (click)="onSkip()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M6 17.9999L17.9999 6" stroke="#192435" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.9999 17.9999L6 6" stroke="#192435" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</div>
<div>
    <!-- <mat-form-field class="searchable-select-field">
        <mat-label>To</mat-label>
        <input type="text"
         placeholder="Search Xero Contacts"
         matInput [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete (optionSelected)="onOptionSelected($event)" #auto="matAutocomplete" [displayWith]="displayFn">            
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{option.name}}</mat-option>
        </mat-autocomplete>
    </mat-form-field> -->
    <mat-form-field class="searchable-select-field">
        <mat-label>To</mat-label>
        <input 
          type="text" 
          matInput 
          [formControl]="myControl" 
          [matAutocomplete]="auto" 
          placeholder="Search Xero Contacts" 
        />
        <mat-autocomplete 
          #auto="matAutocomplete" 
          [displayWith]="displayFn" 
          (optionSelected)="onOptionSelected($event)" 
          panelClass="custom-autocomplete-panel"
        >
          <!-- Regular Options -->
          <mat-option 
            *ngFor="let option of filteredOptions | async" 
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
          
          <mat-option 
            class="create-new-option fixed-create-new" 
            (click)="onCreateNew($event)"
            [disableRipple]="true" 
            [value]="null"
            >
            + Create New
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
</div>
<div class="dialog-footer"></div>
