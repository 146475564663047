import { Injectable } from '@angular/core';
import { config } from '../../config';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(protected apiService: ApiService) { }

  async checkBatchJob(payload): Promise<any>{
    let url = config.base_url_slug  + "invoice/isInvoiceJobRunning";
    try {
      return await this.apiService.post(url, payload);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }


}
