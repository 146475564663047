import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-creditnote-creation-confirmation-dialog',
  templateUrl: './creditnote-creation-confirmation-dialog.component.html',
  styleUrls: ['./creditnote-creation-confirmation-dialog.component.scss']
})
export class CreditnoteCreationConfirmationDialogComponent implements OnInit {

  isSplitInvoice: boolean = false;
  splitInvoice: any;
  invoice: any;

  @Inject(MAT_DIALOG_DATA) public data: any
  constructor(
    protected dialog: MatDialog,
		protected dialogRef: MatDialogRef<CreditnoteCreationConfirmationDialogComponent>,	
  ) { }

  ngOnInit(): void {
    console.log('CREDIT NOTE POPUP ===============>', this.data)
    if(this.data.splitInvoice){
      this.isSplitInvoice = true;
      this.splitInvoice = this.data.splitInvoice;
      this.invoice = this.data.payload
    }else{
      this.invoice = this.data
    }
  }

  onConfirm(){
    console.log('IN CN POPUP')
    this.dialogRef.close('confirmed')
  }

  onCancel(){
    console.log('IN CN POPUP')
    this.dialogRef.close('cancelled');
  }

}
