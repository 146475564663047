import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class LocalStorageService 
{
    constructor(){}

    getHeadOffice(){
        return localStorage.getItem("headOfficeId");
    }

    setHeadOffice(payload: any){
        return localStorage.setItem("headOfficeId", payload)
    }

    getBranch(){
        return localStorage.getItem("branchId");
    }
}
