import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-rollback-response-dialog',
  templateUrl: './rollback-response-dialog.component.html',
  styleUrls: ['./rollback-response-dialog.component.scss']
})
export class RollbackResponseDialogComponent implements OnInit {

  @Inject(MAT_DIALOG_DATA) public data: any

  isSplitInvoice: boolean = false;
  splitInvoice: any;
  invoice: any;
  creditlist: any;
  redirectUrl = '/main/finance/credits'

  constructor(
    protected dialog: MatDialog,
		protected dialogRef: MatDialogRef<RollbackResponseDialogComponent>,	
  ) { }

  ngOnInit(): void {
    console.log('RESPONSE POPUP ===============>', this.data)
    if(this.data.isSplitInvoice){
      this.isSplitInvoice = true;
      this.splitInvoice = this.data.splitInvoice;
      this.invoice = this.data.payload
    }else{
      this.invoice = this.data
    }
    this.creditlist = this.data.credits
  }

  onOk(){
    this.dialogRef.close("ok");
  }


}
