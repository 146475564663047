import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MapXeroContactDto, XeroContactDto } from '../../../../models/models';
import { XeroService } from '../../../../services/xero.service';
import { AlertService, ApiService } from '../../../../services';
import { LocalStorageService } from '../../../../services/localStorage.service';
import { config } from '../../../../../config';

@Component({
  selector: 'app-link-with-existing-contact',
  templateUrl: './link-with-existing-contact.component.html',
  styleUrls: [
    './link-with-existing-contact.component.scss',
    '../../../shared-style.scss'
  ],
})

export class LinkWithExistingContactComponent implements OnInit {
  
  guardian: any;
  myControl = new FormControl('');
  filteredOptions: Observable<XeroContactDto[]>;
  selectedOption: XeroContactDto  
  options: XeroContactDto[] = []; 
  @Inject(MAT_DIALOG_DATA) public data: any

  constructor(
    protected dialog: MatDialog, 
    protected dialogRef: MatDialogRef<LinkWithExistingContactComponent>,
    protected _xeroService: XeroService,
    protected _alertService: AlertService,
    protected _localStorageService: LocalStorageService,
    protected _apiService: ApiService,
  ) {}
  async ngOnInit(){
    this.guardian = this.data.guardian;
    await this.getXeroContactList()
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
  }

  async getXeroContactList(){
    const response = await this._xeroService.getXeroContactsList();
    if(!response.success){
      this._alertService.alertError("Error", response.message);
    }
    this.options = response.data;
  }

  displayFn(user: XeroContactDto): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): XeroContactDto[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  async onOptionSelected(event: any) {
    if (event.option.value === null) {
      console.log("Create new clicked")
      return;
    }
    this.selectedOption = event.option.value;
    this.dialogRef.close({message: "map_contact", selectedOption: this.selectedOption})
  }

  onCreateNew(event: MouseEvent): void {
    event.stopPropagation();
    console.log('Create New button clicked!');
    this.dialogRef.close('new')
  }

  onSkip(){
    this.dialogRef.close('skipped');
  }
}
