import { Injectable } from '@angular/core';
import { IntegrationsService } from './integrations.service'
import { Integration } from '../models/models';
import { IntegrationEnums } from '../enums/integrations.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  isXeroEnabled: boolean;
  private _isXeroEnabled$ = new BehaviorSubject<boolean | null>(null); 
  isXeroEnabled$ = this._isXeroEnabled$.asObservable();

  constructor(private _integrationService: IntegrationsService) { 
    
    this._integrationService.integrations$.subscribe(
        (integrations: Integration []) => {
        console.log(integrations, '<===== Finance Service');
        const xero: Integration | undefined = integrations.find((ixn: Integration)=>{
          return ixn.platform === IntegrationEnums.XERO;
        }) 
        
        if(!xero){
          this.isXeroEnabled = false;
          return;
        }
        this._isXeroEnabled$.next(xero?.isEnabled || false);
        this.isXeroEnabled = xero.isEnabled       
      }
    );
  }

}
