import { Component, Inject, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-initiate-invoice-rollback-dialog',
  templateUrl: './initiate-invoice-rollback-dialog.component.html',
  styleUrls: ['./initiate-invoice-rollback-dialog.component.scss']
})
export class InitiateInvoiceRollbackDialogComponent implements OnInit {
  @Inject(MAT_DIALOG_DATA) public data: any

  isSplitInvoice: boolean = false;
  splitInvoice: any;
  invoice: any;

  constructor(
    protected dialog: MatDialog,
		protected dialogRef: MatDialogRef<InitiateInvoiceRollbackDialogComponent>,	
  ) { }

  ngOnInit(): void {
    console.log('ROLLBACK POPUP============>', this.data)
    if(this.data.splitInvoice){
      this.isSplitInvoice = true;
      this.splitInvoice = this.data.splitInvoice;
      this.invoice = this.data.payload
    }else{
      this.invoice = this.data
    }
  }

  onConfirm(){
    console.log('IN ROLLBACK POPUP')
    this.dialogRef.close('confirmed')
  }

  onCancel(){
    console.log('IN ROLLBACK POPUP')
    this.dialogRef.close('cancelled');
  }

}
