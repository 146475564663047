export default function arrayItemRemover(arr: any, key: any, value: any){
    const removableObj = arr.findIndex((obj: any) => 
        {
           return obj.key === value
        });
    
    if (removableObj > -1) {
      arr.splice(removableObj, 1);
    }
  
    return arr;
}