import { Component, Input, OnInit, Output } from '@angular/core';
import { GlobalListComponent } from '../../../../../shared/global-list';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ApiService, PermissionService } from '../../../../../services';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { config } from '../../../../../../config';
import * as moment from 'moment';
import { XeroService } from '../../../../../services/xero.service';
import { XeroSalesLedgerResponse } from '../../../../../models/response';

@Component({
  selector: 'app-sales-ledger',
  templateUrl: './sales-ledger.component.html',
  styleUrls: ['./sales-ledger.component.scss']
})
export class SalesLedgerComponent extends GlobalListComponent implements OnInit {

  tableConfigAndProps = {};
  salesLedger = [];
  declare dataItems: XeroSalesLedgerResponse[];
  dataSource = new MatTableDataSource();
  buttonHeaderProps: any;
  guardianId: any;
  @Output() outstandingBalance: EventEmitter<any> = new EventEmitter<any>()
  @Input() xeroContact: string;

  
  inputData = {
    'imageColumn': 'profilePicture',
    'actionColumn': 'Actions',
    'expandColumn': 'expandable',
    'firstColumn': 'No.',
    'lastColumn': '',
    'roundedTable': false,
    'hasSwitch': false,
    'buttonEvent': "output",
    'hyperLink': true
  }
  constructor(protected router: Router, protected apiService: ApiService, protected _route: ActivatedRoute, protected alertService: AlertService, protected permissionsService: PermissionService, protected dialog: MatDialog, protected _xeroService: XeroService) { 
    super(router, apiService, alertService, permissionsService)
    this.actionButtons =
      [
        { buttonLabel: "View in Xero", buttonRoute: "", visibility: true },
      ]
    this.headerButtons = [
    ]
    // , 'Actions': 'Actions'
    this.columnHeader = {
      'date': 'Date', 'type': 'Type', 'status': 'Status', 'credit': 'Credit', 'debit': 'Debit','outstanding': 'Outstanding','due': 'Due', 'Actions': 'Actions'
    };
    this.tableConfigAndProps = {
      ActionButtons: this.actionButtons,
      inputData: this.inputData, columnHeader: this.columnHeader, dataSource: this.dataSource,
    };

    this.buttonHeaderProps = {
      headingLabel: "Session ",
      ActionButtons: this.headerButtons,
      hasButton: true,
      hasHeading: true,
      labelMargin: '10px',
      textclass: 'text-bolder text-color',
      margin: '0px',
      hasFilters: true,
      searchConfig: { label: 'Search', key: 'branchId', value: '' },
      builtInFilters: { key: 'branchId', value: localStorage.getItem('branchId') }
    };
    let sub = this._route.params.subscribe(params =>
    {
      this.guardianId = params['id'];
    })

    if (this.guardianId != 'add')
    // {
    //   this.getSalesLedger();
    // }
    super.ngOnInit();
  }

  ngOnInit(): void {
    if (this.guardianId != 'add')
      {
        this.getSalesLedger();
      }
  }

  async getSalesLedger(){
    if(!this.xeroContact){
      return;  
    }
    const response = await this._xeroService.getGuardianSalesLedger(this.xeroContact)  
    this.dataItems = response.data;
    this.afterListResponse();
  }


  afterListResponse(): void
  {
    let outstandingAmount: number = 0;
    this.dataItems.forEach(element =>
    {
      element['date'] = moment(element.date).format(config.cmsDateFormat);
      element['type'] = element.type
      element['status'] = element.status
      element['credit'] = element.amountCredited
      element['debit'] = element.amountPaid
      element['outstanding'] = element.amountDue
      element['due'] = 0 || element.amountDue;
      

      outstandingAmount += element.amountDue;
    });

    this.outstandingBalance.emit(outstandingAmount);
 
    this.tableConfigAndProps = {
      ActionButtons: this.actionButtons,
      inputData: this.inputData,
      columnHeader: this.columnHeader,
      dataSource: new MatTableDataSource(this.dataItems),
      pagination: this.pagination,
      onlyDelete: false
    };
  }

}
