/*
    This service is used for formatting urls used in the anchor tags for opening records 
    in new tab on browser
*/

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleTabEnums } from '../enums/module-tab.enum'; 
import { ExternalLinkEnums } from '../enums/external-links.enum'; 
import { TabPayload } from '../interfaces/tab-payload.interface';

@Injectable()
export class NewTabService 
{    
    lastSegment: string = '';
    externalLink: string = '';

    constructor(private router: Router) {}

    formatExternalLinkProperty(payload: TabPayload): string {
        let compiledUrl: string;
        const parsedUrl = this.parseUrl(this.router.url);
        this.lastSegment = parsedUrl.lastUrlSegment;
        console.log(this.lastSegment);          
        switch (this.lastSegment) {
            case ModuleTabEnums.BOOKINGS:
                this.externalLink = payload?.externalLink! ;
                compiledUrl =  `${this.router.url}/${payload.id}/${this.externalLink}`;
                break;
        
            case ModuleTabEnums.CHILDREN:
                this.externalLink = payload.externalLink || ExternalLinkEnums.VIEW ;
                compiledUrl =  `${parsedUrl.precedingUrl}/enrolment/${payload.id}/${this.externalLink}`;
                break;
        
            case ModuleTabEnums.GUARDIAN:
                this.externalLink = payload.externalLink || ExternalLinkEnums.VIEW ;
                compiledUrl =  `${this.router.url}/${payload.id}/${this.externalLink}`;
                break;
        
            default:          
                compiledUrl = "";       
                break;
        }      

        return compiledUrl;
    }

    parseUrl(url: string): any {
        const urlTree = this.router.parseUrl(url);
        const segments = urlTree.root.children['primary'].segments.map(it => it.path);
        const lastUrlSegment = segments.pop();        
        const precedingUrl=  '/' + segments.join('/');
        return {
            precedingUrl,
            lastUrlSegment
        }
    }

}

