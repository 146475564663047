import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { MaterialModule } from '../material.module';
import { GlobalFormComponent } from './global-form';
import { ParentFormComponent } from './parent-form.component';
import { GlobalListComponent } from './global-list';
import { ViewMoreDialogComponent } from './view-more-dialog/view-more-dialog.component';
import { TopTableHeaderComponent } from './top-table-header/top-table-header.component';
import { TopHeadingComponent } from './top-heading/top-heading.component';
import { OverrideReccuringDialogComponent } from './override-reccuring-dialog/override-reccuring-dialog.component';
import { MultipleBookingsComponent } from './multiple-bookings/multiple-bookings.component';
import { ImageRowComponent } from './image-row/image-row.component';
import { FormFoooterComponent } from './form-foooter/form-foooter.component';
import { EndBookingDialogComponent } from './end-booking-dialog/end-booking-dialog.component';
import { ConfigAlertComponent } from './config-alert/config-alert.component';
import { CardWidgetComponent } from './card-widget/card-widget.component';
import { BookingTypeDialogComponent } from './booking-type-dialog/booking-type-dialog.component';
import { BookingPatterenDialogComponent } from './booking-patteren/booking-patteren-dialog.component';
import { AttendanceBreakListComponent } from './attendance-break-list/attendance-break-list.component';
import { AdvancedSettingsDialogComponent } from './advanced-settings-dialog/advanced-settings-dialog.component';
import { AdditionalItemsFormDialogComponent } from './additional-items-form-dialog/additional-items-form-dialog.component';
import { TableComponentComponent } from '../core/table-component/table-component.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InvoiceTableComponent } from './invoice-table/invoice-table.component';
import { AlertService, ApiService, LoaderService } from '../services';


const componentsArray = [
    GlobalFormComponent,
    ParentFormComponent,
    GlobalListComponent,
    ViewMoreDialogComponent,
    TopTableHeaderComponent,
    TopHeadingComponent,
    OverrideReccuringDialogComponent,
    MultipleBookingsComponent,
    ImageRowComponent,
    FormFoooterComponent,
    EndBookingDialogComponent,
    ConfigAlertComponent,
    CardWidgetComponent,
    BookingTypeDialogComponent,
    BookingPatterenDialogComponent,
    AttendanceBreakListComponent,
    AdvancedSettingsDialogComponent,
    AdditionalItemsFormDialogComponent,
    TableComponentComponent,
    InvoiceTableComponent
]

@NgModule({
  declarations: [
    ...componentsArray,    
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    CoreModule,
  ],
  exports:[
    ...componentsArray
  ],
  providers:[
    ApiService,
		LoaderService,
		AlertService,
  ]
})
export class SharedModule {}
