export const config =
{
  // staging
  file_url: 'http://13.126.174.129:2500/api/v1/afforestation/',
  
 
 // base_url will be automatically fetched from the server for the correct environment
  base_url: "https://api-dev.sdnapp.net/api/v1/sdn/",

 // For local development, uncomment the base_url below and use
 // Ensure to comment out before pushing changes to GitHub
 
  // will be replaced with respective enviroment lambda url
  lambda_url: "https://dev-public-api.sdnapp.net/xero",

 // base_url: "https://api-dev.sdnapp.net/api/v1/sdn/",
//  base_url: "https://api-dev.sdnapp.net/api/v1/sdn/",


    // base_url will be automatically fetched from the server for the correct environment
    //   base_url: "https://api-dev.sdnapp.net/api/v1/sdn/",

    // For local development, uncomment the base_url below and use
    // Ensure to comment out before pushing changes to GitHub

    // base_url: "https://api-dev.sdnapp.net/api/v1/sdn/",
    // base_url: "https://api-dev.sdnapp.net/api/v1/sdn/",


    base_url_slug: 'staff/', // admin | gardener | staff

    default_auth_key: 'andpercent',

    google_api_key: 'AIzaSyDykNkzGorhQSSUDKkJUzSYFMK04_YeHQ4',
    serverDateFormat: 'YYYY-MM-DD', // For send value to server side
    cmsDateFormat: 'DD/MM/YYYY', // For display date
    cmsTimeFormat: 'hh:mm A', // For display time
    staticDateForTime: '01-01-2000', // For server side comparison

    sessionHistory: 'DD MMM YYYY', // For session pricing logs
    build_version: '2.0',
    clientId: '105df879-5776-4584-ac15-c8a90a9b2ae5',

    // Module Keys
    md_child_m: 'Child Management',
    sub_md_child_additional_items: 'Additional Items',
    sub_md_child_attendance: 'Attendance',
    sub_md_child_booking: 'Booking',
    sub_md_child_enrollment: 'Enrollment',
    sub_md_child_report: 'Report',
    md_finance_m: 'Finance Management',
    sub_md_finance_Credit: 'Credit',
    sub_md_finance_invoice: 'Invoice',
    md_global_s: 'Global Settings',
    sub_md_access_management: 'Access Management',
    sub_md_hr_calendar: 'Calender',
    md_hr_m: 'H.R Management',
    sub_md_hr_attendance: 'Attendance',
    // sub_md_hr_calendar:'Calender',
    sub_md_hr_staff: 'Staff',
    md_occupancy_m: 'Occupancy Management',
    sub_md_occupancy_branch: 'Branch',
    sub_md_occupancy_room: 'Room',
    md_session_m: 'Session Management',
    agmAPIKey: "AIzaSyCE-pIIADNwC_gDRKGAdbU7wA4SkMV0TR0"
}

