import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-link-to-xero-contact-popup',
  templateUrl: './link-to-xero-contact-popup.component.html',
  styleUrls: [
    './link-to-xero-contact-popup.component.scss',
    '../../../shared-style.scss'
  ]
})
export class LinkToXeroContactPopupComponent implements OnInit {

  @Inject(MAT_DIALOG_DATA) public data: any

  constructor(
    protected dialog: MatDialog, 
    protected dialogRef: MatDialogRef<LinkToXeroContactPopupComponent>
  ) {}

  ngOnInit(): void {
  }

  onSkip(){
    this.dialogRef.close('skipped');
  }

  onLinkWithExistingContact(){
    this.dialogRef.close('existing');
  }

  onCreateNewContact(){
    this.dialogRef.close('new');
  }

}
