import { DebugElement, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { checkXeroStatusDto, Integration, toggleXeroDto } from '../models/models';
import { IntegrationEnums } from '../enums/integrations.enum';

import { LambdaService } from './lambda.service';
import { XeroService } from './xero.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  private integrations = new BehaviorSubject<Integration[]>([]);
  integrations$ = this.integrations.asObservable();

  constructor(
    protected _lambdaService: LambdaService,
    protected _xeroService: XeroService,
    protected _alertService: AlertService
   ) {
    this.initializeIntegrations();
  }

  async toggleIntegration(enabled: boolean, integration: Integration) {
    
    const filteredIntegration : Integration = this.integrations.value.find((ixn: Integration) => ixn.id===integration.id)!

    const isIntegrationEnabled = await this.toggleSelectedIntegration(integration);
    filteredIntegration.isEnabled = isIntegrationEnabled


    const updatedIntegrations : Integration[] = this.integrations.value.map((ixn: Integration) => {
      if(ixn.id === filteredIntegration.id){
          ixn.isEnabled = filteredIntegration.isEnabled
      }
      return ixn;
    })
    this.integrations.next(updatedIntegrations)  
  }

  async initializeIntegrations(){
    try {
      const headOfficeId = localStorage.getItem('headOfficeId')
			if(!headOfficeId){
				console.error('Head office id not found');
				return;				
			}
			
			const requestPayload: checkXeroStatusDto = {
				organizationId: parseInt(headOfficeId),
			}
      const xeroResponse = await this._xeroService.getXeroStatus(requestPayload);
      let integrations: Integration[] = [];
      if(!xeroResponse.success){
        integrations.push({
					id: 1,
					label: 'Xero',
					isEnabled: false,
					platform: IntegrationEnums.XERO
				})
        this.integrations.next(integrations)
        return;
      }  

      if(xeroResponse.data.hasOwnProperty('isXeroEnabled') && xeroResponse.data.isXeroEnabled){
				integrations.push({
					id: 1,
					label: 'Xero',
					isEnabled: true,
					platform: IntegrationEnums.XERO
				})
			}else{
        integrations.push({
					id: 1,
					label: 'Xero',
					isEnabled: false,
					platform: IntegrationEnums.XERO
				})
			}
      this.integrations.next(integrations)
      return;  
    } catch (error) {
      console.error(error);
    }
  
  }

  async toggleSelectedIntegration(selectedIntegration: Integration): Promise<boolean>{ 
    try {
      let toggleState: boolean;
      switch (selectedIntegration.platform) {
        case IntegrationEnums.XERO:
          toggleState = await this._xeroService.toggleXeroService(selectedIntegration);
          break;
      
        default:
          toggleState = !selectedIntegration.isEnabled
          break;
      }   
      return toggleState;  
    } catch (error) {
      this._alertService.alertError("Error", error);
      return !selectedIntegration.isEnabled;
    }
  }

}
