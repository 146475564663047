import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from 'src/config';
import { GlobalListComponent } from 'src/app/shared/global-list';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CreditNoteDataItems, XeroCreditNoteStatusDto } from '../../../models/models';
import filterOverDue from '../../../helpers/dueDateChecker';
import { XeroStatusEnums } from '../../../enums/xeroStatus.enum';


import { ApiService, AlertService, PermissionService } from 'src/app/services';
import { XeroService } from '../../../services/xero.service';
import { FinanceService } from '../../../services/finance.service';
import { ServerlessResponse, XeroCreditNoteStatusesResponse } from '../../../models/response';
import { LocalStorageService } from '../../../services/localStorage.service';

export class selectedItem
{
  id: string;
  childId: string;
}

@Component({
  selector: 'app-credit-list.component',
  templateUrl: './credit-list.component.html',
  styleUrls: ['./credit-list.component.scss', '/src/app/views/shared-style.scss']
})
export class CreditListComponent extends GlobalListComponent implements OnInit
{
  tableConfigAndProps = {};
  dataSource = new MatTableDataSource();
  layoutAlign = "start start";
  columnHeader = {};
  buttonHeaderProps: any;
  public date = moment();
  @Input() selectedItems: selectedItem[] = [];
  headerCheckBoxValue: boolean = false;

  headerProps = {
    searchConfig: {
      label: 'Enter Child Name',
      key: 'branchId',
      value: ''
    },
    builtInFilters: {
      key: 'branchId',
      value: localStorage.getItem('branchId')
    },
    filterArray: [{
      label: 'Select Room',
      type: 'date',
      key: 'roomId',
      selected: 'All',
      options: this.rooms
    },],
  };
  inputData = {
    'imageColumn': 'profilePicture',
    'actionColumn': 'Actions',
    'expandColumn': 'expandable',
    'firstColumn': 'No.',
    'lastColumn': '',
    'roundedTable': false,
    'hasSwitch': false,
    'buttonEvent': "output",
    'hasCheckBox': this.permissionsService.getPermissionsBySubModuleName('Finance Management', 'Credit').read,
    'checkBoxCol': "checkbox",
    'onlyOneCheck': false,
  }

  constructor(protected router: Router, protected apiService: ApiService, protected _route: ActivatedRoute, protected alertService: AlertService, protected dialog: MatDialog,
    protected _financeService: FinanceService,
    protected _xeroService: XeroService,
    protected _localStorageService: LocalStorageService,
    protected permissionsService: PermissionService)
  {
    super(router, apiService, alertService, permissionsService);

    this.listApi = config.base_url_slug + 'view/childs/credits';
    this.getRoomsforDropdown(localStorage.getItem('branchId'));
    // 'buttonEvent': 'output'  on events
    this.headerButtons = [{
      buttonLabel: "Export as CSV",
      color: "#E2AF2A",
      buttonRoute: "",
      type: "output",
      visibility: false
    },
    ];
    this.buttonHeaderProps = {
      headingLabel: "New Credits",
      hasRightLabel: false,
      rightLabel: "date time",
      ActionButtons: this.headerButtons,
      hasButton: true,
      hasHeading: true,
      labelMargin: '0px',
      float: 'right',
      textclass: 'text-bolder text-color',
      buttonsMargin: '0px 10px 0px',
      margin: '10px',
      // builtInFilters: { key: 'branchId', value: localStorage.getItem('branchId') }
    };
    this.actionButtons = [{
      buttonLabel: "Edit",
      type: 'edit',
      buttonRoute: "",
      visibility: this.permissionsService.getPermissionsBySubModuleName('Finance Management', 'Credit').update
    },
    {
      buttonLabel: "View",
      type: 'view',
      buttonRoute: "",
      visibility: this.permissionsService.getPermissionsBySubModuleName('Finance Management', 'Credit').read
    },
    {
      buttonLabel: "Delete",
      type: 'delete',
      buttonRoute: "child/credit",
      visibility: this.permissionsService.getPermissionsBySubModuleName('Finance Management', 'Credit').delete
    },
    ]
    this.columnHeader = {
      'checkbox': '',
      'serializedId': 'ID',
      'childName': 'Child Name',
      'invoiceId': 'Invoice ID', 
      'startDate': 'Date',
      'amount': 'Amount',
    };

    this.tableConfigAndProps = {
      ActionButtons: this.actionButtons,
      inputData: this.inputData,
      columnHeader: this.columnHeader,
      dataSource: this.dataSource,
    };

    this.headerProps.searchConfig.label = 'Search by Child Name';

    super.ngOnInit();
    this.setCsvVisibility();
  }

  ngOnInit() {
     this._financeService.isXeroEnabled$.subscribe((isXeroEnabled) => {
      if(isXeroEnabled){
       this.columnHeader['xeroStatus'] = 'Xero Status';       
      }
      this.afterRoom();
      this.afterListResponse();
    });
  }

  startXeroCreditsJob(page: number, perPage: number){    
      const branchId = this._localStorageService.getBranch();
      const requestUri = config.base_url_slug + `invoice/startXeroCreditsJob?branchId=${branchId}&page=${page}&perPage=${perPage}`;
      this.apiService.get(requestUri).then((res) => {
        console.log(res);
      }).catch((err: any) => {
        console.log(err);
      })
  }

  afterRoom()
  {
    this.headerProps = {
      searchConfig: {
        label: 'Search by Child Name',
        key: 'branchId',
        value: ''
      },
      builtInFilters: {
        key: 'branchId',
        value: localStorage.getItem('branchId')
      },
      filterArray: []
    };
  }

  async afterListResponse()
  {    
    const pageNo = this.page;
    const perPage = this.perPage;
    let xeroResponse: ServerlessResponse<XeroCreditNoteStatusesResponse[]> | undefined;
    if(this._financeService.isXeroEnabled){
      xeroResponse = await this.getXeroCreditNoteStatuses(this.dataItems);
      this.startXeroCreditsJob(pageNo, perPage);
    }
    this.dataItems.forEach(element =>
    {
      element['id'] = element.id;
      element['serializedId'] = element.serializeCreditId;
      element['childName'] = element.childNames;
      element['amount'] = '£' + element['amount'].toFixed(2);
      element['startDate'] = moment(element.creditDate).format(config.cmsDateFormat);
      // element['status'] = element.creditStatus
      element['invoiceId'] = element.sequenceInvoiceID || element.invoiceId
      

      if(this._financeService.isXeroEnabled){
        const xeroInvoice = xeroResponse?.data.find(cr => cr.creditNoteNumber === element.serializeCreditId);
        if(xeroInvoice){
          if(xeroInvoice.status === "AUTHORISED" && filterOverDue(element.dueDate) ){
            element['xeroStatus'] = XeroStatusEnums.OVERDUE
          }else{
            element['xeroStatus'] = XeroStatusEnums[xeroInvoice.status as keyof typeof XeroStatusEnums] || "--";
          }
        }else{
          element['xeroStatus'] = "--";
        }
      }
    });

    this.tableConfigAndProps = {
      ActionButtons: this.actionButtons,
      inputData: this.inputData,
      columnHeader: this.columnHeader,
      dataSource: new MatTableDataSource(this.dataItems),
      pagination: this.pagination,
      onlyDelete: true
    };
  }

   async getXeroCreditNoteStatuses(dataItems: CreditNoteDataItems[]){
    try {
      const creditNoteNumbers = dataItems.map(cr => cr.serializeCreditId);
      const requestPayload: XeroCreditNoteStatusDto = {
        creditNoteNumbers: creditNoteNumbers
      }  
      const xeroResponse = await this._xeroService.getXeroCreditStatuses(requestPayload);
      if(!xeroResponse.success){
        console.log("Api unsuccessfull in invoice listing ========> ", xeroResponse.message);
        return;
      }
      return xeroResponse;
    } catch (error) {
      throw error;
    } 
  }

  actionButtonOutput(event)
  {
    return; 
    console.log('actionButtonOutput ==> ', event);
    let id = event.row.id;
    let url = '/main/finance/credits/';
    if (event.item.type === "view")
    {
      url = url + 'view/' + id;
      this.router.navigateByUrl(url);
    } else if (event.item.type === "edit")
    {
      url = url + 'edit/' + id;
      this.router.navigateByUrl(url);
    }
    else if (event.item.type === "delete")
    {
      this.onDelete(event.row, event.item)
    }
    else
    {
      // this.openDialog(event);
    }
  }

  onSelectedCheckBoxAllEmit(input)
  {    
    this.dataItems.forEach(item =>
    {
      let child = this.selectedItems.find(t => item.id === t);
      if (child)
      {
        if (input == false)
        {
          const index = this.selectedItems.indexOf(child, 0);
          if (index > -1)
          {
            this.selectedItems.splice(index, 1);
          }
        }
      }
      else
      {
        this.selectedItems.push(item.id);
      }
    });

    this.setCsvVisibility();
  }

  onSelectedCheckBoxEmit(res)
  {
    if (res.checked == true)
    {
      let child = this.selectedItems.find(t => res.element.id === t);
      if (!child)
      {
        this.selectedItems.push(res.element.id);
      }
    } else
    {
      for (var i = 0; i < this.selectedItems.length; i++)
      {
        if (this.selectedItems[i] == res.element.id)
        {
          this.selectedItems.splice(i, 1);
          break;
        }
      }
    }

    this.setCsvVisibility();
  }

  setCsvVisibility()
  {
    if (this.permissionsService.getPermissionsBySubModuleName('Finance Management', 'Credit').read && this.selectedItems.length > 0)
    {
      this.buttonHeaderProps['ActionButtons'][0].visibility = true;
    }
    else
    {
      this.buttonHeaderProps['ActionButtons'][0].visibility = false;
    }
  }

  onHeaderCheckBoxValueEmit(input)
  {
    this.headerCheckBoxValue = input;
    // this.onSelectedCheckBoxAllEmit(input);
  }

  onSaveasCsv() {    
    let csvEndpoint = config.base_url_slug + "v2/export/childs/credits-csv";
    
    this.apiService.post(csvEndpoint, {creditsIds: this.selectedItems}).then((res)=> {
      console.log(res);
      if (res.code == 200 || res.code == 201 || res.code == 202) {
        this.downloadCSV(res.data);
        // this.router.navigate(['main/finance/allInvoice']);
        // this.alertService.alertSuccess('SUCCESS', 'Invoice Generated Successfully');
      }
    })
    .catch(err => console.log(err));
    
  }

  downloadCSV(url) {
    let link = document.createElement('a');
    link.href = url;
    link.download = url.substr(url.lastIndexOf('/') + 1);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  filnalFilters(event): void
  {
    let filterUrl = '';
    console.log('filnalFilters', event);
    let branchId = localStorage.getItem('branchId')
    if (event.range)
    {
      filterUrl = filterUrl + event.range + '&branchId=' + branchId;
    } else
    {
      let startDate = this.date.startOf('month').format(config.serverDateFormat);
      let endDate = this.date.endOf('month').format(config.serverDateFormat);
      filterUrl = filterUrl + 'startDate=' + startDate + '&endDate=' + endDate + '&branchId=' + branchId;
    }

    if (event.search)
    {
      filterUrl = filterUrl + event.search;
    }

    if (event.date)
    {
      filterUrl = filterUrl + event.date;
    }

    console.log('filnalFilters', filterUrl);
    // filterUrl = filterUrl + "&otherAttributes=" + JSON.stringify(_otherAttributes);
    this.getList(filterUrl);
  }
}
