import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../../config';
// import axios from 'axios'
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LambdaService
{
    sdnUser: any = {};
    headers: HttpHeaders;
    options: any;
    public lambdaUrl: string

    constructor(private http: HttpClient, protected loaderService: LoaderService,)
    {
        this.lambdaUrl = config.lambda_url
        this.options = { headers: this.headers, observe: 'response' };
    }
    
    public lambdaGet(url: string): Promise<any>{
        this.loaderService.setLoading(true);

        return this.http.get(this.lambdaUrl + url).toPromise().then((response: any) =>
        {
            return this.onResponse(response, 'response');
        }, (reason: any) =>
        {
            return this.onResponse(reason, 'error');
        }).catch(this.handleError);
    }

    public lambdaPost(url: string, payload: any): Promise<any>{
        this.loaderService.setLoading(true);

        let data: any = payload;
        
        return this.http.post(this.lambdaUrl + url, data).toPromise().then((response: any) =>
        {
            return this.onResponse(response, 'response');
        }).catch(this.handleError);

    }

	/**
	 * onResponse
	 */
    public onResponse(response, type)
    {
        setTimeout(() =>
        {
            this.loaderService.setLoading(false);
        }, 800);

        if (type == 'response')
        {
            if (response.statusCode === 403)
            {
                localStorage.clear();
                window.location.reload();
            }
            else
            {
                return response;
            }
        }else{
            return response
        }
        // else
        // {
        //     if (response.error.code === 401)
        //     {
        //         localStorage.clear();
        //         window.location.reload();
        //         let res = {
        //             code: response.error.code,
        //             status: "ERROR",
        //             message: 'Unauthorized user.',
        //         }
        //         return res;
        //     }
        //     else if (response.error.code === 403)
        //     {
        //         localStorage.clear();

        //         let res = {
        //             code: response.error.code,
        //             status: "ERROR",
        //             message: 'Unauthorized user , you can not access the route.',
        //         }
        //         return res;
        //     }
        //     else if (response.error.code === 422)
        //     {
        //         let res = {
        //             code: response.error.code,
        //             status: "ERROR",
        //             message: response.error.data,
        //         }
        //         return res;
        //     }
        //     else if (response.error.code === 500)
        //     {
        //         let res = {
        //             code: response.error.code,
        //             status: "ERROR",
        //             message: response.error.message,
        //         }
        //         return res;
        //     }
        //     else
        //     {
        //       if (response.statusText == 'Unknown Error' && response.status == 0 ){
        //         let res = {
        //           status: "Warning",
        //           message: 'Please Check your Internet Connection',
        //       }
        //       return res;
        //       }
        //       else{
        //         return response;
        //       }
        //     }
        // }
    }

    public handleError(error: any): Promise<any>
    {
        throw error;
    }
}
