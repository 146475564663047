<mat-card style="background-color: #ffffff26 !important; border: 0px !important;">
    <p class="text-normal" style="color: #4A4A4A; line-height: 1px; margin-bottom: -5px;">
     <strong> Enter Invoice Number, Child Name or Parent Name </strong>
    </p>
     <filter-control
         [hasButton]="true"
         [hasClearButton]="true"
         [layoutAlign]="layoutAlign"
         (filnalFilters)="filnalFilters($event)"
         [hasSearch]="true"
         [hasDatePicker]="true"
         [searchConfig]="headerProps?.searchConfig"
         [builtInFilters]="headerProps?.builtInFilters"         
         rangeWidth="250px"
         fxFlexInSearch="200px"
         fxFlexInSort="200px"
         [filterArray]="headerProps?.filterArray"></filter-control>
   </mat-card>
  
  <app-table-component (onPagination)="onPagination($event)"
                     (selectedItem)="actionButtonOutput($event)"
                     [props]="tableConfigAndProps"
                     [isViewClick]="true"
                     [totalAmount]="totalAmount"
                     [selectedTotalAmount]="selectedTotalAmount"
                     [invoiceTab]="tab"
                     [sortFields]="sortFields"
                     height="auto"
                     (emitColumnSortAction)="sortColumn($event)"
                     (emitSelectedCheckBox)="onSelectedCheckBoxEmit($event)"
                     (emitSelectedCheckBoxAll)="onSelectedCheckBoxAllEmit($event)"
                     (emitHeaderCheckBoxValue)="onHeaderCheckBoxValueEmit($event)"
                     [hasHeaderCheckBox]="true" #table></app-table-component>
                    <!-- </app-table-component> -->
  