<form [formGroup]="Form" (ngSubmit)="onSubmit()">
  <ng-container>
    <!-- <mat-card class="card"> -->
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      fxLayoutGap="15px"
      class="top-heading text-bolder text-color"
    >
      <div fxFlex>
        {{ fundingHeading }}
      </div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      fxLayoutGap="15px"
      class="top-heading text-bolder"
    >
      <div fxFlex>Funding Type</div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      class="row-wrap-margins"
    >
      <div
        fxFlex.xl="28"
        fxFlex.lg="58"
        fxFlex.md="58"
        fxFlex.sm="45"
        fxFlex.xs="100"
        class="col-flex-margins"
      >
        <!-- <mat-form-field>
          <mat-select class="height-40"
                      formControlName="fundingId">
            <mat-option [value]=1
                        selected>Not Funded</mat-option>
            <mat-option [value]=2>Free 15 hours 2 years old</mat-option>
            <mat-option [value]=3>Free 15 hours 3/4 years old</mat-option>
            <mat-option [value]=4>Free 30 hours 3/4 years old</mat-option>
            <mat-option [value]=6>5+ years old</mat-option>
            <mat-option [value]=5>Custom Funding</mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field>
          <mat-label>Select Funding Type</mat-label>
          <input
            type="text"
            aria-label="Number"
            matInput
            required
            [formControl]="Form.get('fundingLabel')"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            (optionSelected)="setValue()"
          >
            <mat-option
              *ngFor="let option of filteredFundingTypes"
              [value]="option.label"
            >
              {{ option.label }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="getField('fundingLabel')">{{
            getErrorMessage("fundingLabel")
          }}</mat-error>
        </mat-form-field>
      </div>
      <div
        fxFlex.xl="28"
        fxFlex.lg="20"
        fxFlex.md="20"
        fxFlex.sm="45"
        fxFlex.xs="100"
        class="col-flex-margins"
      >
        <mat-checkbox
          formControlName="stretch"
          *ngIf="
          (Form.controls.fundingId.value == '2' ||
            Form.controls.fundingId.value == '3' ||
            Form.controls.fundingId.value == '4') &&
          showStretch
        " 
        >        
          Stretch this funding</mat-checkbox
        >
      </div>

      <div
        fxFlex.xl="28"
        fxFlex.lg="20"
        fxFlex.md="20"
        fxFlex.sm="45"
        fxFlex.xs="100"
      ></div>
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        *ngIf="Form.controls['fundingId'].value == 5"
      >
        <div fxFlex="25" class="custom-text text-gray">Custom Funding</div>

        <div
          fxFlex.xl="28"
          fxFlex.lg="32"
          fxFlex.md="32"
          fxFlex.sm="45"
          fxFlex.xs="100"
          class="col-flex-margins"
        >
          <mat-form-field>
            <input
              matInput
              type="number"
              formControlName="selfFinanceHoursPerWeek"
              min="1"
              max="30"
              maxlength="3"
              (blur)="onBlurEvent($event)"
              required
            />
            <mat-error
              *ngIf="Form.get('selfFinanceHoursPerWeek').hasError('min')"
            >
              Minimum Limit is 1</mat-error
            >
            <mat-error
              *ngIf="Form.get('selfFinanceHoursPerWeek').hasError('max')"
            >
              Max Limit is 30</mat-error
            >
          </mat-form-field>
        </div>
        <div fxFlex="10" class="custom-text text-gray">hrs</div>
        <div
          fxFlex.xl="28"
          fxFlex.lg="20"
          fxFlex.md="20"
          fxFlex.sm="45"
          fxFlex.xs="100"
          class="col-flex-margins"
          *ngIf="showStretch"
        >
          <mat-checkbox formControlName="stretch">
            Stretch this funding</mat-checkbox
          >
        </div>
      </div>
    </div>

    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      fxLayoutGap="15px"
      class="top-heading text-bolder"
    >
      Funding Period
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="40" class="row-wrap-margins">
        <mat-form-field (click)="desired.open()">
          <mat-label>Start Date <span style="color: red">*</span></mat-label>
          <input
            matInput            
            (dateChange)="dateChangeStatic(Form, 'startDate', $event)"
            formControlName="matstartDate"
            [matDatepicker]="desired"
            [max]="!canEditStartDate ? fundingStartDate : null"
          />
          <!-- [disabled]="hasInvoiceGenerated" -->
          <mat-icon matSuffix>
            <img
              [src]="calendar"
              style="
                margin-bottom: 0px;
                margin-right: 10px;
                width: 16px;
                vertical-align: baseline;
                cursor: pointer;
              "
            />
          </mat-icon>
          <mat-datepicker #desired></mat-datepicker>
          <mat-error *ngIf="getField('matstartDate')">{{
            getErrorMessage("matstartDate")
          }}</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="40" class="row-wrap-margins">
        <mat-form-field (click)="desired1.open()">
          <mat-label>End Date </mat-label>
          <input
            matInput
            (dateChange)="dateChangeStatic(Form, 'endDate', $event)"
            formControlName="matendDate"
            [matDatepicker]="desired1"
            [ngClass]="{'mat-form-field-disabled': hasInvoiceGenerated}"            
            [min]="Form.get('matstartDate').value"
            [matDatepickerFilter]="endDateFilter"
          />
          <!-- [disabled]="!Form.get('matstartDate').value || hasInvoiceGenerated" -->
          <mat-icon matSuffix>
            <img
              [src]="calendar"
              style="
                margin-bottom: 0px;
                margin-right: 10px;
                width: 16px;
                vertical-align: baseline;
                cursor: pointer;
              "
            />
          </mat-icon>
          <mat-datepicker #desired1></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div
      *ngIf="Form.get('fundingId').value != 1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div fxFlex="40" class="row-wrap-margins">
        <mat-form-field [disabled]="hasInvoiceGenerated">
          <mat-label>Funding passcode</mat-label>
          <input
            matInput
            type="text"
            formControlName="passCode"
            minlength="3"
            [required]="isPassCodeRequired"
            (change)="checkPassCode()"            
            maxlength="18"
          />
          <mat-error *ngIf="getField('passCode')">{{
            getErrorMessage("passCode")
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div
      fxLayout="row"
      class="row-wrap-margins mb-30"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <div fxFlex="150px">
        <button
          (click)="closeFundingDialog()"
          type="button"
          mat-button
          [style.background-color]="'#C1BBB9'"
          class="btn-custom-form"
        >
          Cancel
        </button>
      </div>
      <div fxFlex="150px">
        <button type="submit" [disabled]="hasInvoiceGenerated" mat-button class="btn-custom-form">
          {{ buttonLabel }}
        </button>
      </div>
    </div>
  
    <!-- </mat-card> -->
  </ng-container>
</form>
