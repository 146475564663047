<div>
    <!-- <div class="dialog-container">
          <mat-icon [ngStyle]="{ 'color': '#FFAB00' }" class="icon-class" style="font-size: 25px; cursor: pointer; vertical-align: middle;">error_outline</mat-icon>
    </div>
    <hr> -->
    <form [formGroup]="contactForm" class="contact-name-form" (submit)="onSubmit()">
      <div style="text-align: center; margin-bottom: 15px;">
            <span id="contact-title">Confirmation</span>
            <p style="margin-top: 15px; color: #555; font-size: 14px !important;">
                  Are you sure you want to link the profile with the Xero contact below?
            </p>
      </div>

      <div style="text-align: center; padding-left: 20px; padding-right: 20px;">
                  <div class="detail-item" *ngIf="hasXeroContact">
                        <span class="details-heading">Contact Name:</span>
                        <span class="info-value">                        
                              {{data?.selectedXeroContact?.name}}
                        </span>
                  </div>
                  <div class="detail-item" *ngIf="!hasXeroContact">
                        <span class="details-heading">Contact Name:</span>
                        <span class="info-value">
                              <input class="contact-name-input" 
                                    matInput type="text"
                                    placeholder="Enter contact name"
                                    formControlName="contactName"
                                    [class.contact-name-input-error]="contactForm?.get('contactName')?.invalid && (contactForm?.get('contactName')?.touched || contactForm?.get('contactName')?.dirty)"
                              />
                              <div *ngIf="contactForm?.get('contactName')?.invalid && (contactForm?.get('contactName')?.touched || contactForm?.get('contactName')?.dirty)"> 
                                    <small class="error-msg" *ngIf="contactForm?.get('contactName')?.errors.required">
                                          Contact name is required
                                    </small>
                                    <small class="error-msg" *ngIf="contactForm.get('contactName')?.errors?.contactExists">
                                          Contact name already exists on xero
                                    </small>
                              </div>
                        </span>     
                  </div>
            <div class="detail-item">
                  <span class="details-heading">Email:</span>
                  <span class="info-value">{{contact?.email}}</span>
            </div>
            <div class="detail-item">
                  <p><span class="details-heading">Name:</span></p>                  
                  <span class="info-value">{{contact?.name}}</span>
            </div>
            <div class="detail-item">
                  <span class="details-heading">Address:</span>
                  <span class="info-value">{{contact?.address}}</span>
            </div>
            <div class="detail-item">
                  <span class="details-heading">Contact no:</span>
                  <span class="info-value">{{contact?.contactNo}}</span>
            </div>
      </div>
      <div class="btn-container">
            <button
            type="button"
            (click)="onCancel()"
            class="btn-cancel">
                  No
            </button>
            <!-- (click)="onConfirm()" -->
            <button  
                  type="submit"
                  [disabled]="contactForm.invalid || isAsyncValidating"
                  [ngClass]="{'btn-confirm-disabled': contactForm.invalid || isAsyncValidating}"
                  class="btn-confirm">
                  Yes
            </button>
      </div>
    </form>     
</div>
